const Component = {
  init() {
    const $videoHolders = $('.js-video-holder')
    if ($videoHolders.length) {
      $videoHolders.each(function () {
        const $this = $(this)
        const videoSrc = $this.data('src')
        $this
          .parent()
          .html(
            '<video class="of-cover" autoplay muted loop playsinline preload="none"><source src="' +
              videoSrc +
              '" type="video/mp4" /></video>'
          )
      })
    }
  },
}

$(() => {
  Component.init()
})
